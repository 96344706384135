<template>
  <div class="width">
    <div style="font-size: 0">
      <img class="home_01" src="https://oss.topfavor.cn/assets/pc/home_01.png" />
      <img class="home_02" src="https://oss.topfavor.cn/assets/pc/home_02.gif" />
      <!-- <img class="home_03" src="https://oss.topfavor.cn/assets/pc/home_03.png" /> -->
      <video class="home_03" autoplay :muted='isMuted' loop="loop" playsinline controls poster="https://oss.topfavor.cn/assets/pc/home_03.png">
        <source src="https://oss.topfavor.cn/assets/pc/movie_01.mp4" type="video/mp4"  />
      </video>
      <img class="home_04" src="https://oss.topfavor.cn/assets/pc/home_04.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      isMuted:true
    
    }
  },
  mounted() {
    let video = document.querySelectorAll("video")[0];
    const _this = this
    document.addEventListener("WeixinJSBridgeReady",function() {
      _this.isMuted = false
        video.play()
    },false);
    
  },
  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
  .home_01 {
    width: 1.8rem;
    height: 0.45rem;
    margin: 0.83rem auto;
    display: block;
  }
  .home_02 {
    width: 5.815rem;
    height: 10.065rem;
    margin: 0.395rem auto;
    display: block;
  }
  .home_03 {
    width: 5.815rem;
    height: 2.725rem;
    margin: 0.21rem auto;
    display: block;
  }
  .home_04 {
    width: 2.69rem;
    height: 0.29rem;
    margin: 0 auto 1.055rem;
    display: block;
  }
</style>
