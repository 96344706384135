import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isM: true,
    isM2: false
  },
  mutations: {
    changeM(state, data) {
      state.isM = data
    },
    changeM2(state, data) {
      state.isM2 = data
    },
  },
  actions: {

  },
  modules: {
  }
})
